import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import { useAuth } from '../../../../../../context/AuthContext';
import { useTranslation } from "react-i18next";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { text } from "@fortawesome/fontawesome-svg-core";
import "./createquestion.css";

export const AdminCreateQuestionDetail = () => {
    const params = useParams()
    const navigate = useNavigate();
    const [msg, setMsg] = useState([]);
    const [imagePath, setImagePath] = useState(null);
    const [videoPath, setVideoPath] = useState(null);
    const [soundPath, setSoundPath] = useState(null);

    const [loading, setLoading] = useState(true);
    const { t, i18n } = useTranslation();
    const { user } = useAuth();

    const [serviceList, setServiceList] = useState([{ service: "" }]);
    const [serviceListmulti, setServiceListmulti] = useState([{ service: "" }]);

    const handleServiceRemove = (index) => {
        const list = [...serviceList];
        list.splice(index, 1);
        setServiceList(list);
    };
    const handleServiceAdd = () => {
        console.log(serviceList);
        setServiceList([...serviceList, { service: "" }]);
    };
    const handleServiceChange = (e, index) => {
        console.log(e, index);

        const { name, value } = e.target;
        const list = [...serviceList];
        list[index][name] = value;
        setServiceList(list);
    };
    const handleAnswerPhoto = async (e, index) => {
        const formData = new FormData();
        formData.append('FormFile', e.target.files[0]);
        try {
            const headerss = {
                "accept": "*/*",
                'Content-Type': 'multipart/form-data',
                'Access-Control-Allow-Credentials': 'false',
                'Authorization': 'Bearer ' + user.token
            }
            const url = 'https://api.heryerdeingilizce.com/api/admin/ContentFile/UploadFile';
            await axios({
                method: 'Post',
                url: url,
                params: {
                    mediaType: "photo"
                },
                data: formData,
                headers: headerss
            }).then(res => {
                let url = "https://api.heryerdeingilizce.com/" + res.data['data']['path']
                setImagePath(url)
                alert("Yüklendi")
                const { name, value } = e.target;
                const list = [...serviceList];
                list[index][name] = url;;
                setServiceList(list);
                console.log(serviceList);
            });
        } catch (err) {
            setMsg(err.message);
        }

    };
    const handleAnswerSound = async (e, index) => {
        const formData = new FormData();
        formData.append('FormFile', e.target.files[0]);
        try {
            const headerss = {
                "accept": "*/*",
                'Content-Type': 'multipart/form-data',
                'Access-Control-Allow-Credentials': 'false',
                'Authorization': 'Bearer ' + user.token
            }
            const url = 'https://api.heryerdeingilizce.com/api/admin/ContentFile/UploadFile';
            await axios({
                method: 'Post',
                url: url,
                params: {
                    mediaType: "photo"
                },
                data: formData,
                headers: headerss
            }).then(res => {
                let url = "https://api.heryerdeingilizce.com/" + res.data['data']['path']
                setImagePath(url)
                alert("Yüklendi")
                const { name, value } = e.target;
                const list = [...serviceList];
                list[index][name] = url;;
                setServiceList(list);
                console.log(serviceList);
            });
        } catch (err) {
            setMsg(err.message);
        }

    };


    //////multi match service
    const handleAnswerPhoto1 = async (e, index) => {
        const formData = new FormData();
        formData.append('FormFile', e.target.files[0]);
        try {
            const headerss = {
                "accept": "*/*",
                'Content-Type': 'multipart/form-data',
                'Access-Control-Allow-Credentials': 'false',
                'Authorization': 'Bearer ' + user.token
            }
            const url = 'https://api.heryerdeingilizce.com/api/admin/ContentFile/UploadFile';
            await axios({
                method: 'Post',
                url: url,
                params: {
                    mediaType: "photo"
                },
                data: formData,
                headers: headerss
            }).then(res => {
                let url = "https://api.heryerdeingilizce.com/" + res.data['data']['path']
                setImagePath(url)
                alert("Yüklendi")
                const { name, value } = e.target;
                const list = [...serviceListmulti];
                list[index][name] = url;;
                setServiceListmulti(list);
                console.log(serviceListmulti);
            });
        } catch (err) {
            setMsg(err.message);
        }

    };
    const handleAnswerPhoto2 = async (e, index) => {
        const formData = new FormData();
        formData.append('FormFile', e.target.files[0]);
        try {
            const headerss = {
                "accept": "*/*",
                'Content-Type': 'multipart/form-data',
                'Access-Control-Allow-Credentials': 'false',
                'Authorization': 'Bearer ' + user.token
            }
            const url = 'https://api.heryerdeingilizce.com/api/admin/ContentFile/UploadFile';
            await axios({
                method: 'Post',
                url: url,
                params: {
                    mediaType: "photo"
                },
                data: formData,
                headers: headerss
            }).then(res => {
                let url = "https://api.heryerdeingilizce.com/" + res.data['data']['path']
                setImagePath(url)
                alert("Yüklendi")
                const { name, value } = e.target;
                const list = [...serviceListmulti];
                list[index][name] = url;;
                setServiceListmulti(list);
                console.log(serviceList);
            });
        } catch (err) {
            setMsg(err.message);
        }

    };

    const handleAnswerSound1 = async (e, index) => {
        const formData = new FormData();
        formData.append('FormFile', e.target.files[0]);
        try {
            const headerss = {
                "accept": "*/*",
                'Content-Type': 'multipart/form-data',
                'Access-Control-Allow-Credentials': 'false',
                'Authorization': 'Bearer ' + user.token
            }
            const url = 'https://api.heryerdeingilizce.com/api/admin/ContentFile/UploadFile';
            await axios({
                method: 'Post',
                url: url,
                params: {
                    mediaType: "photo"
                },
                data: formData,
                headers: headerss
            }).then(res => {
                let url = "https://api.heryerdeingilizce.com/" + res.data['data']['path']
                setImagePath(url)
                alert("Yüklendi")
                const { name, value } = e.target;
                const list = [...serviceList];
                list[index][name] = url;;
                setServiceList(list);
                console.log(serviceList);
            });
        } catch (err) {
            setMsg(err.message);
        }

    };
    const handleAnswerSound2 = async (e, index) => {
        const formData = new FormData();
        formData.append('FormFile', e.target.files[0]);
        try {
            const headerss = {
                "accept": "*/*",
                'Content-Type': 'multipart/form-data',
                'Access-Control-Allow-Credentials': 'false',
                'Authorization': 'Bearer ' + user.token
            }
            const url = 'https://api.heryerdeingilizce.com/api/admin/ContentFile/UploadFile';
            await axios({
                method: 'Post',
                url: url,
                params: {
                    mediaType: "photo"
                },
                data: formData,
                headers: headerss
            }).then(res => {
                let url = "https://api.heryerdeingilizce.com/" + res.data['data']['path']
                setImagePath(url)
                alert("Yüklendi")
                const { name, value } = e.target;
                const list = [...serviceList];
                list[index][name] = url;;
                setServiceList(list);
                console.log(serviceList);
            });
        } catch (err) {
            setMsg(err.message);
        }

    };

    const handleServiceAdd1 = () => {
        console.log(serviceListmulti);
        setServiceListmulti([...serviceListmulti, { service: "" }]);
    };
    const handleServiceChange1 = (e, index) => {
        const { name, value } = e.target;
        const list = [...serviceListmulti];
        list[index][name] = value;
        setServiceListmulti(list);
    };
    const handleServiceChange2 = (e, index) => {
        const { name, value } = e.target;
        const list = [...serviceListmulti];
        list[index][name] = value;
        setServiceListmulti(list);
    };
    const handleServiceRemove1 = (index) => {
        const list = [...serviceListmulti];
        list.splice(index, 1);
        setServiceListmulti(list);
    };

    //////multi match service


    const handletype6 = async (e) => {
        console.log(serviceListmulti)
    }




    const handletype5 = async (e) => {
        e.preventDefault();
        var answer = document.getElementById("correctAnswer5").value;
        var question;
        let questiontext = document.getElementById("questionText5").value;
        if (imagePath) {
            question = {
                type: "image",
                url: imagePath,
                text: questiontext
            }
        }
        else if (soundPath) {
            question = {
                type: "sound",
                url: soundPath,
                text: questiontext
            }
        }
        else if (videoPath) {
            question = {
                type: "video",
                url: videoPath,
                text: questiontext
            }
        }
        else {
            question = {
                type: "text",
                url: "",
                text: questiontext
            }
        }
        var stringquestion = JSON.stringify(question)
        var obj = []
        for (let i = 0; i < serviceList.length; i++) {
            let list = {
                id: i,
                service: serviceList[i]['service']
            }
            obj.push(list)
        }
        var answertext = JSON.stringify(obj)
        var info = document.getElementById("infoBoxText5").value;

        try {
            const headerss = {
                "accept": "*/*",
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials': 'false',
                'Authorization': 'Bearer ' + user.token
            }
            let url = "https://api.heryerdeingilizce.com/api/admin/ContentManager/Create";
            const data = {
                isDeleted: false,
                tagId: params["tag"],
                sortId: params["sort"],
                contentType: "truefalse",
                subjectId: params["subject"],
                contentStr: stringquestion,
                detail: answertext,
                notfyText: info,
                answer: answer
            };

            await axios({
                method: 'Post',
                url: url,
                data: data,
                headers: headerss
            }).then(res => {
                if (res.status === 200) {
                    if (res.data['success']) {
                        Swal.fire({
                            title: "Başırılı!",
                            icon: "success",
                            confirmButtonText: `Tamam`,
                            timer: 3000,
                        }).then(() => {
                            navigate("/dashboard/admin/createquestion");
                        });
                    }
                    else {
                        setMsg(res.data['message']);
                    }
                }
                else {
                    setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                }
            });
        } catch (err) {
            alert(err.message)
            setMsg(err.message);
        }
    }
    const handletype3 = async (e) => {
        e.preventDefault();
        var answer = document.getElementById("correctAnswer3").value;
        var question;
        let questiontext = document.getElementById("questionText3").value;
        if (imagePath) {
            question = {
                type: "image",
                url: imagePath,
                text: questiontext
            }
        }
        else if (soundPath) {
            question = {
                type: "sound",
                url: soundPath,
                text: questiontext
            }
        }
        else if (videoPath) {
            question = {
                type: "video",
                url: videoPath,
                text: questiontext
            }
        }
        else {
            question = {
                type: "text",
                url: "",
                text: questiontext
            }
        }
        var stringquestion = JSON.stringify(question)
        var obj = []
        for (let i = 0; i < serviceList.length; i++) {
            let list = {
                id: i,
                service: serviceList[i]['service'],
                photo: serviceList[i]['photo'],
            }
            obj.push(list)
        }
        var answertext = JSON.stringify(obj)
        var info = document.getElementById("infoBoxText3").value;

        try {
            const headerss = {
                "accept": "*/*",
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials': 'false',
                'Authorization': 'Bearer ' + user.token
            }
            let url = "https://api.heryerdeingilizce.com/api/admin/ContentManager/Create";
            const data = {
                isDeleted: false,
                tagId: params["tag"],
                sortId: params["sort"],
                contentType: "match",
                subjectId: params["subject"],
                contentStr: stringquestion,
                detail: answertext,
                notfyText: info,
                answer: answer
            };
            await axios({
                method: 'Post',
                url: url,
                data: data,
                headers: headerss
            }).then(res => {
                if (res.status === 200) {
                    if (res.data['success']) {
                        Swal.fire({
                            title: "Başırılı!",
                            icon: "success",
                            confirmButtonText: `Tamam`,
                            timer: 3000,
                        }).then(() => {
                            navigate("/dashboard/admin/createquestion");
                        });
                    }
                    else {
                        setMsg(res.data['message']);
                    }
                }
                else {
                    setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                }
            });
        } catch (err) {
            alert(err.message)
            setMsg(err.message);
        }
    }
    const handletype2 = async (e) => {
        e.preventDefault();
        var answer = document.getElementById("correctAnswer2").value;
        var question;
        let questiontext = document.getElementById("questionText2").value;
        if (imagePath) {
            question = {
                type: "image",
                url: imagePath,
                text: questiontext
            }
        }
        else if (soundPath) {
            question = {
                type: "sound",
                url: soundPath,
                text: questiontext
            }
        }
        else if (videoPath) {
            question = {
                type: "video",
                url: videoPath,
                text: questiontext
            }
        }
        else {
            question = {
                type: "text",
                url: "",
                text: questiontext
            }
        }
        var stringquestion = JSON.stringify(question)
        var obj = []
        for (let i = 0; i < serviceList.length; i++) {
            let list = {
                id: i,
                service: serviceList[i]['service'],
                photo: serviceList[i]['photo'],
            }
            obj.push(list)
        }
        var answertext = JSON.stringify(obj)
        var info = document.getElementById("infoBoxText2").value;

        try {
            const headerss = {
                "accept": "*/*",
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials': 'false',
                'Authorization': 'Bearer ' + user.token
            }
            let url = "https://api.heryerdeingilizce.com/api/admin/ContentManager/Create";
            const data = {
                isDeleted: false,
                tagId: params["tag"],
                sortId: params["sort"],
                contentType: "choice",
                subjectId: params["subject"],
                contentStr: stringquestion,
                detail: answertext,
                notfyText: info,
                answer: answer
            };
            console.log(data)

            await axios({
                method: 'Post',
                url: url,
                data: data,
                headers: headerss
            }).then(res => {
                console.log(data)
                console.log(res.data);
                if (res.status === 200) {
                    if (res.data['success']) {
                        Swal.fire({
                            title: "Başırılı!",
                            icon: "success",
                            confirmButtonText: `Tamam`,
                            timer: 3000,
                        }).then(() => {
                            navigate("/dashboard/admin/createquestion");
                        });
                    }
                    else {
                        setMsg(res.data['message']);
                    }
                }
                else {
                    setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                }
            });
        } catch (err) {
            alert(err.message)
            setMsg(err.message);
        }
    }






    const handleChange = async (event) => {
        const formData = new FormData();
        formData.append('FormFile', event.target.files[0]);
        try {
            const headerss = {
                "accept": "*/*",
                'Content-Type': 'multipart/form-data',
                'Access-Control-Allow-Credentials': 'false',
                'Authorization': 'Bearer ' + user.token
            }
            const url = 'https://api.heryerdeingilizce.com/api/admin/ContentFile/UploadFile';
            await axios({
                method: 'Post',
                url: url,
                params: {
                    mediaType: "video"
                },
                data: formData,
                headers: headerss
            }).then(res => {
                setVideoPath(res.data['data']['path'])
                alert("Yüklendi")
            });
        } catch (err) {
            setMsg(err.message);
        }
    }
    const handlePhotoChange = async (event) => {
        const formData = new FormData();
        formData.append('FormFile', event.target.files[0]);
        try {
            const headerss = {
                "accept": "*/*",
                'Content-Type': 'multipart/form-data',
                'Access-Control-Allow-Credentials': 'false',
                'Authorization': 'Bearer ' + user.token
            }
            const url = 'https://api.heryerdeingilizce.com/api/admin/ContentFile/UploadFile';
            await axios({
                method: 'Post',
                url: url,
                params: {
                    mediaType: "photo"
                },
                data: formData,
                headers: headerss
            }).then(res => {
                setImagePath(res.data['data']['path'])
                alert("Yüklendi");
            });
        } catch (err) {
            setMsg(err.message);
        }
    }
    const handleSound = async (event) => {
        const formData = new FormData();
        formData.append('FormFile', event.target.files[0]);
        try {
            const headerss = {
                "accept": "*/*",
                'Content-Type': 'multipart/form-data',
                'Access-Control-Allow-Credentials': 'false',
                'Authorization': 'Bearer ' + user.token
            }
            const url = 'https://api.heryerdeingilizce.com/api/admin/ContentFile/UploadFile';
            await axios({
                method: 'Post',
                url: url,
                params: {
                    mediaType: "sound"
                },
                data: formData,
                headers: headerss
            }).then(res => {
                setSoundPath(res.data['data']['path'])
                alert("Yüklendi")
            });
        } catch (err) {
            setMsg(err.message);
        }
    }

    const handletype1 = async (e) => {
        e.preventDefault();
        try {
            const headerss = {
                "accept": "*/*",
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials': 'false',
                'Authorization': 'Bearer ' + user.token
            }
            let url = "https://api.heryerdeingilizce.com/api/admin/ContentManager/Create";
            await axios({
                method: 'Post',
                url: url,
                data: {
                    isDeleted: false,
                    tagId: params["tag"],
                    sortId: params["sort"],
                    contentType: "video",
                    subjectId: params["subject"],
                    contentStr: videoPath,
                    detail: "",
                    notfyText: "",
                    answer: ""
                },
                headers: headerss
            }).then(res => {
                if (res.status === 200) {
                    if (res.data['success']) {
                        Swal.fire({
                            title: "Başırılı!",
                            icon: "success",
                            confirmButtonText: `Tamam`,
                            timer: 3000,
                        }).then(() => {
                            navigate("/dashboard/admin/createquestion");
                        });
                    }
                    else {
                        setMsg(res.data['message']);
                    }
                }
                else {
                    setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                }
            });
        } catch (err) {
            setMsg(err.message);
        }
    }

    const handletype4 = async (e) => {
        var question;
        let questiontext = document.getElementById("questionText4").value;
        if (imagePath) {
            question = {
                type: "image",
                url: imagePath,
                text: questiontext
            }
        }
        else if (soundPath) {
            question = {
                type: "sound",
                url: soundPath,
                text: questiontext
            }
        }
        else if (videoPath) {
            question = {
                type: "video",
                url: videoPath,
                text: questiontext
            }
        }
        else {
            question = {
                type: "text",
                url: "",
                text: questiontext
            }
        }
        var answertext = document.getElementById("answerText4").value;
        var answer = document.getElementById("correctAnswer4").value;
        var info = document.getElementById("infoBoxText4").value;
        // if (info === " ") {
        //     info = null;
        // }
        e.preventDefault();
        var stringquestion = JSON.stringify(question)
        try {
            const headerss = {
                "accept": "*/*",
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials': 'false',
                'Authorization': 'Bearer ' + user.token
            }
            let url = "https://api.heryerdeingilizce.com/api/admin/ContentManager/Create";
            await axios({
                method: 'Post',
                url: url,
                data: {
                    isDeleted: false,
                    tagId: params["tag"],
                    sortId: params["sort"],
                    contentType: "write",
                    subjectId: params["subject"],
                    contentStr: stringquestion,
                    detail: answertext,
                    notfyText: info,
                    answer: answer
                },
                headers: headerss
            }).then(res => {
                console.log(res.data);
                if (res.status === 200) {
                    if (res.data['success']) {
                        Swal.fire({
                            title: "Başırılı!",
                            icon: "success",
                            confirmButtonText: `Tamam`,
                            timer: 3000,
                        }).then(() => {
                            navigate("/dashboard/admin/createquestion");
                        });
                    }
                    else {
                        setMsg(res.data['message']);
                    }
                }
                else {
                    setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                }
            });
        } catch (err) {
            alert(err.message)
            setMsg(err.message);
        }
    }


    useEffect(() => {
        // console.log(params["subject"]);
        // console.log("questiontype : " + params["questiontype"]);
        // console.log(params["sort"]);
    }, []);

    return (
        <div>
            { }
            {
                (() => {
                    //video
                    if (params["questiontype"] === "1") {
                        return (
                            <div>
                                <br />
                                <h2>Video Ders Oluşturma Sayfası</h2>
                                <br />
                                <hr />
                                <br />
                                <form onSubmit={handletype1}>
                                    <div class="mb-3">
                                        <label for="videocontent" class="form-label">{t("addVideo")}</label>
                                        <input class="form-control" type="file" accept='video' onChange={handleChange} id="videocontent" name="videocontent" required />
                                    </div>

                                    <div className='d-flex justify-content-center'>
                                        <button type="submit" class="btn btn-primary btn-lg">{t("create")}</button>
                                    </div>
                                </form>
                            </div>
                        )
                    }
                    //choice
                    else if (params["questiontype"] === "2") {
                        return (
                            <div>
                                <br />
                                <h2>Çoktan Seçmeli Oluşturma Sayfası</h2>
                                <br />
                                <hr />
                                <br />
                                <br />
                                <h5>add file to question</h5>
                                <hr />
                                <div className="row">
                                    <div className="col-md-4">
                                        <div class="mb-1">
                                            <label for="addphoto" class="form-label">{t("addPhoto")}</label>
                                            <input class="form-control" type="file" accept='image/png, image/jpg, image/jpeg' onChange={handlePhotoChange} id="addphoto" name="addphoto" required />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div class="mb-3">
                                            <label for="addvideo" class="form-label">{t("addVideo")}</label>
                                            <input class="form-control" type="file" accept='video' onChange={handlePhotoChange} id="addvideo" name="addvideo" required />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div class="mb-3">
                                            <label for="addsound" class="form-label">{t("addSound")}</label>
                                            <input class="form-control" type="file" accept=".mp3,audio/*" onChange={handlePhotoChange} id="addsound" name="addsound" required />
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <form onSubmit={handletype2} >
                                    <div class="mb-3">
                                        <label for="questionText2" class="form-label">{t("questionText")}</label><br /><i>boş ise 1 ader boşluk giriniz. (sadece video/resim vb var ise)</i>
                                        <textarea class="form-control" id="questionText2" required />
                                    </div>

                                    <div class="mb-3">
                                        <label for="correctAnswer2" class="form-label">{t("correctAnswer")}</label> <br /><i>0/1/2/3/4/5 olarak hangi seçenek olduğunu belirtiniz lütfen</i>
                                        <textarea class="form-control" id="correctAnswer2" required />
                                    </div>
                                    <div class="mb-3">
                                        <label for="infoBoxText2" class="form-label">{t("infoBoxText")}</label> <br /><i>boş ise 1 ader boşluk giriniz.</i>
                                        <textarea class="form-control" id="infoBoxText2" required />
                                    </div>
                                    <form className="App" autoComplete="off">
                                        <div className="form-field">
                                            <h5>{t('answers')}</h5>
                                            {serviceList.map((singleService, index) => (
                                                <div key={index} className="services">
                                                    <div className="first-division">

                                                        <div class="mb-1">
                                                            <label for="photo" class="form-label">{t("addPhoto")}</label>
                                                            <input class="form-control" type="file" accept='image/png, image/jpg, image/jpeg' onChange={(e) => handleAnswerPhoto(e, index)} id="photo" name="photo" required />
                                                        </div>
                                                        <label for="service" class="form-label">{t("answerText")}</label><i>boş ise 1 ader boşluk giriniz. (sadece video/resim vb var ise)</i>
                                                        <textarea class="form-control" name="service" id="service" value={singleService.service} onChange={(e) => handleServiceChange(e, index)} required />
                                                        <hr />
                                                        {serviceList.length - 1 === index && serviceList.length < 6 && (
                                                            <button
                                                                type="button"
                                                                onClick={handleServiceAdd}
                                                                className="add-btn"
                                                            >
                                                                <span>{t('addAnswerOption')}</span>
                                                            </button>
                                                        )}
                                                    </div>
                                                    <div className="second-division">
                                                        {serviceList.length !== 1 && (
                                                            <button
                                                                type="button"
                                                                onClick={() => handleServiceRemove(index)}
                                                                className="remove-btn"
                                                            >
                                                                <span>{t('remove')}</span>
                                                            </button>
                                                        )}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>

                                        <div className="output">
                                            <h2>{t('answers')}</h2>
                                            {serviceList &&
                                                serviceList.map((singleService, index) => (
                                                    <ul key={index}>
                                                        {singleService.service &&
                                                            <li>
                                                                {singleService.service} & {singleService.photo}
                                                            </li>}
                                                    </ul>
                                                ))}
                                        </div>
                                    </form>
                                    <div className='d-flex justify-content-center'>
                                        <button type="submit" class="btn btn-primary btn-lg">{t("create")}</button>
                                    </div>


                                </form>
                                <hr />
                                <hr />

                            </div>
                        )
                    }
                    //match
                    else if (params["questiontype"] === "3") {
                        return (
                            <div>
                                <br />
                                <h2>Eşleştirme Oluşturma Sayfası</h2>
                                <br />
                                <hr />
                                <br />
                                <br />
                                <h4>{t('addFile')}</h4>
                                <hr />
                                <div className="row">
                                    <div className="col-md-4">
                                        <div class="mb-1">
                                            <label for="addphoto" class="form-label">{t("addPhoto")}</label>
                                            <input class="form-control" type="file" accept='image/png, image/jpg, image/jpeg' onChange={handlePhotoChange} id="addphoto" name="addphoto" required />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div class="mb-3">
                                            <label for="addvideo" class="form-label">{t("addVideo")}</label>
                                            <input class="form-control" type="file" accept='video' onChange={handlePhotoChange} id="addvideo" name="addvideo" required />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div class="mb-3">
                                            <label for="addsound" class="form-label">{t("addSound")}</label>
                                            <input class="form-control" type="file" accept=".mp3,audio/*" onChange={handlePhotoChange} id="addsound" name="addsound" required />
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <form onSubmit={handletype3} >
                                    <div class="mb-3">
                                        <label for="questionText3" class="form-label">{t("questionText")}</label><br /><i>boş ise 1 ader boşluk giriniz. (sadece video/resim vb var ise)</i>
                                        <textarea class="form-control" id="questionText3" required />
                                    </div>

                                    <div class="mb-3">
                                        <label for="correctAnswer3" class="form-label">{t("correctAnswer")}</label><br /><i>Lütfen büyük harfler ile yazınız.</i>
                                        <textarea class="form-control" id="correctAnswer3" required />
                                    </div>
                                    <div class="mb-3">
                                        <label for="infoBoxText3" class="form-label">{t("infoBoxText")}</label> <br /><i>boş ise 1 ader boşluk giriniz.</i>
                                        <textarea class="form-control" id="infoBoxText3" required />
                                    </div>
                                    <form className="App" autoComplete="off">
                                        <div className="form-field">
                                            <h5>{t('answers')}</h5>
                                            {serviceList.map((singleService, index) => (
                                                <div key={index} className="services">
                                                    <div className="first-division">

                                                        <div class="mb-1">
                                                            <label for="photo" class="form-label">{t("addPhoto")}</label>
                                                            <input class="form-control" type="file" accept='image/png, image/jpg, image/jpeg' onChange={(e) => handleAnswerPhoto(e, index)} id="photo" name="photo" required />
                                                        </div>
                                                        <label for="service" class="form-label">{t("answerText")}</label><i>boş ise 1 ader boşluk giriniz. (sadece video/resim vb var ise)</i>
                                                        <textarea class="form-control" name="service" id="service" value={singleService.service} onChange={(e) => handleServiceChange(e, index)} required />
                                                        <hr />
                                                        {serviceList.length - 1 === index && serviceList.length < 6 && (
                                                            <button
                                                                type="button"
                                                                onClick={handleServiceAdd}
                                                                className="add-btn"
                                                            >
                                                                <span>{t('addAnswerOption')}</span>
                                                            </button>
                                                        )}
                                                    </div>
                                                    <div className="second-division">
                                                        {serviceList.length !== 1 && (
                                                            <button
                                                                type="button"
                                                                onClick={() => handleServiceRemove(index)}
                                                                className="remove-btn"
                                                            >
                                                                <span>{t('remove')}</span>
                                                            </button>
                                                        )}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>

                                        <div className="output">
                                            <h2>{t('answers')}</h2>
                                            {serviceList &&
                                                serviceList.map((singleService, index) => (
                                                    <ul key={index}>
                                                        {singleService.service &&
                                                            <li>
                                                                {singleService.service} & {singleService.photo}
                                                            </li>}
                                                    </ul>
                                                ))}
                                        </div>
                                    </form>
                                    <div className='d-flex justify-content-center'>
                                        <button type="submit" class="btn btn-primary btn-lg">{t("create")}</button>
                                    </div>


                                </form>
                                <hr />
                                <hr />

                            </div>
                        )
                    }
                    //YAZMA
                    else if (params["questiontype"] === "4") {
                        return (
                            <div>
                                <div>
                                    <br />
                                    <h2>Yazma Oluşturma Sayfası</h2>
                                    <br />
                                    <hr />
                                    <br />
                                    <h4>{t('addFile')}</h4>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div class="mb-3">
                                                <label for="addphoto" class="form-label">{t("addPhoto")}</label>
                                                <input class="form-control" type="file" accept='image/png, image/jpg, image/jpeg' onChange={handlePhotoChange} id="addphoto" name="addphoto" />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div class="mb-3">
                                                <label for="addvideo" class="form-label">{t("addVideo")}</label>
                                                <input class="form-control" type="file" accept='video' onChange={handleChange} id="addvideo" name="addvideo" />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div class="mb-3">
                                                <label for="addsound" class="form-label">{t("addSound")}</label>
                                                <input class="form-control" type="file" accept=".mp4,audio/*" onChange={handleSound} id="addsound" name="addsound" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <form onSubmit={handletype4} >
                                    <div class="mb-3">
                                        <label for="questionText4" class="form-label">{t("questionText")}</label><br /><i>boş ise 1 ader boşluk giriniz. (sadece video/resim vb var ise)</i>
                                        <textarea class="form-control" id="questionText4" required />
                                    </div>
                                    <div class="mb-3">
                                        <label for="answerText4" class="form-label">{t("answerText")}</label><br /><i>kelimenin olacağı yere altçizgi koyunuz.</i>
                                        <textarea class="form-control" id="answerText4" required />
                                    </div>
                                    <div class="mb-3">
                                        <label for="correctAnswer4" class="form-label">{t("correctAnswer")}</label>
                                        <textarea class="form-control" id="correctAnswer4" required />
                                    </div>
                                    <div class="mb-3">
                                        <label for="infoBoxText4" class="form-label">{t("infoBoxText")}</label> <br /><i>boş ise 1 ader boşluk giriniz.</i>
                                        <textarea class="form-control" id="infoBoxText4" required />
                                    </div>
                                    <div className='d-flex justify-content-center'>
                                        <button type="submit" class="btn btn-primary btn-lg">{t("create")}</button>
                                    </div>


                                </form>

                            </div>
                        )
                    }
                    else if (params["questiontype"] === "5") {
                        return (
                            <div>
                                <br />
                                <h2>True/False Oluşturma Sayfası</h2>
                                <br />
                                <hr />
                                <br />
                                <br />
                                <h4>{t('addFile')}</h4>
                                <hr />
                                <div className="row">
                                    <div className="col-md-4">
                                        <div class="mb-1">
                                            <label for="addphoto" class="form-label">{t("addPhoto")}</label>
                                            <input class="form-control" type="file" accept='image/png, image/jpg, image/jpeg' onChange={handlePhotoChange} id="addphoto" name="addphoto" required />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div class="mb-3">
                                            <label for="addvideo" class="form-label">{t("addVideo")}</label>
                                            <input class="form-control" type="file" accept='video' onChange={handlePhotoChange} id="addvideo" name="addvideo" required />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div class="mb-3">
                                            <label for="addsound" class="form-label">{t("addSound")}</label>
                                            <input class="form-control" type="file" accept=".mp3,audio/*" onChange={handlePhotoChange} id="addsound" name="addsound" required />
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <form onSubmit={handletype5} >
                                    <div class="mb-3">
                                        <label for="questionText5" class="form-label">{t("questionText")}</label><br /><i>boş ise 1 ader boşluk giriniz. (sadece video/resim vb var ise)</i>
                                        <textarea class="form-control" id="questionText5" required />
                                    </div>

                                    <div class="mb-3">
                                        <label for="correctAnswer5" class="form-label">{t("correctAnswer")}</label><br /><i>Lütfen küçük harfler ile boşluksuz virgüllü yazınız. örn cevap: true,false,true </i>
                                        <textarea class="form-control" id="correctAnswer5" required />
                                    </div>
                                    <div class="mb-3">
                                        <label for="infoBoxText5" class="form-label">{t("infoBoxText")}</label> <br /><i>boş ise 1 ader boşluk giriniz.</i>
                                        <textarea class="form-control" id="infoBoxText5" required />
                                    </div>
                                    <form className="App" autoComplete="off">
                                        <div className="form-field">
                                            <h5>{t('answers')}</h5>
                                            {serviceList.map((singleService, index) => (
                                                <div key={index} className="services">
                                                    <div className="first-division">


                                                        <label for="service" class="form-label">{t("answerText")}</label><i>boş ise 1 ader boşluk giriniz. (sadece video/resim vb var ise)</i>
                                                        <textarea class="form-control" name="service" id="service" value={singleService.service} onChange={(e) => handleServiceChange(e, index)} required />
                                                        <hr />
                                                        {serviceList.length - 1 === index && serviceList.length < 6 && (
                                                            <button
                                                                type="button"
                                                                onClick={handleServiceAdd}
                                                                className="add-btn"
                                                            >
                                                                <span>{t('addAnswerOption')}</span>
                                                            </button>
                                                        )}
                                                    </div>
                                                    <div className="second-division">
                                                        {serviceList.length !== 1 && (
                                                            <button
                                                                type="button"
                                                                onClick={() => handleServiceRemove(index)}
                                                                className="remove-btn"
                                                            >
                                                                <span>{t('remove')}</span>
                                                            </button>
                                                        )}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>

                                        <div className="output">
                                            <h2>{t('answers')}</h2>
                                            {serviceList &&
                                                serviceList.map((singleService, index) => (
                                                    <ul key={index}>
                                                        {singleService.service &&
                                                            <li>
                                                                {singleService.service} & {singleService.photo}
                                                            </li>}
                                                    </ul>
                                                ))}
                                        </div>
                                    </form>
                                    <div className='d-flex justify-content-center'>
                                        <button type="submit" class="btn btn-primary btn-lg">{t("create")}</button>
                                    </div>


                                </form>
                                <hr />
                                <hr />

                            </div>
                        )
                    }
                    //realmatch
                    else if (params["questiontype"] === "6") {
                        return (
                            <div>
                                <br />
                                <h2>Toplu Eşleştirme Oluşturma Sayfası</h2>
                                <br />
                                <hr />
                                <br />
                                <br />
                                <h4>{t('addFile')}</h4>
                                <hr />
                                <div className="row">
                                    <div className="col-md-4">
                                        <div class="mb-1">
                                            <label for="addphoto" class="form-label">{t("addPhoto")}</label>
                                            <input class="form-control" type="file" accept='image/png, image/jpg, image/jpeg' onChange={handlePhotoChange} id="addphoto" name="addphoto" required />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div class="mb-3">
                                            <label for="addvideo" class="form-label">{t("addVideo")}</label>
                                            <input class="form-control" type="file" accept='video' onChange={handlePhotoChange} id="addvideo" name="addvideo" required />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div class="mb-3">
                                            <label for="addsound" class="form-label">{t("addSound")}</label>
                                            <input class="form-control" type="file" accept=".mp3,audio/*" onChange={handlePhotoChange} id="addsound" name="addsound" required />
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <form onSubmit={handletype6} >
                                    <div class="mb-3">
                                        <label for="questionText6" class="form-label">{t("questionText")}</label><br /><i>boş ise 1 ader boşluk giriniz. (sadece video/resim vb var ise)</i>
                                        <textarea class="form-control" id="questionText6" required />
                                    </div>

                                    <div class="mb-3">
                                        <label for="correctAnswer6" class="form-label">{t("correctAnswer")}</label><br /><i>Lütfen büyük harfler ile yazınız.</i>
                                        <textarea class="form-control" id="correctAnswer6" required />
                                    </div>
                                    <div class="mb-3">
                                        <label for="infoBoxText6" class="form-label">{t("infoBoxText")}</label> <br /><i>boş ise 1 ader boşluk giriniz.</i>
                                        <textarea class="form-control" id="infoBoxText6" required />
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <form className="App" autoComplete="off">
                                                <div className="form-field">
                                                    <h5>{t('answers')}</h5>
                                                    {serviceList.map((singleService, index) => (
                                                        <div key={index} className="services">
                                                            <div className="first-division">
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <label for="photo" class="form-label">{t("addPhoto")}</label>
                                                                        <input class="form-control" type="file" accept='image/png, image/jpg, image/jpeg' onChange={(e) => handleAnswerPhoto(e, index)} id="photo" name="photo" />
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <label for="sound" class="form-label">{t("addSound")}</label>
                                                                        <input class="form-control" type="file" accept=".mp3,audio/*" onChange={(e) => handleAnswerSound(e, index)} id="sound" name="sound" />
                                                                    </div>
                                                                </div>
                                                                <label for="service" class="form-label">{t("answerText")}</label><i>boş ise 1 ader boşluk giriniz. (sadece video/resim vb var ise)</i>
                                                                <textarea class="form-control" name="service" id="service" value={singleService.service} onChange={(e) => handleServiceChange(e, index)} required />
                                                                <hr />
                                                                {serviceList.length - 1 === index && serviceList.length < 6 && (
                                                                    <button
                                                                        type="button"
                                                                        onClick={handleServiceAdd}
                                                                        className="add-btn"
                                                                    >
                                                                        <span>{t('addAnswerOption')}</span>
                                                                    </button>
                                                                )}
                                                            </div>
                                                            <div className="second-division">
                                                                {serviceList.length !== 1 && (
                                                                    <button
                                                                        type="button"
                                                                        onClick={() => handleServiceRemove(index)}
                                                                        className="remove-btn"
                                                                    >
                                                                        <span>{t('remove')}</span>
                                                                    </button>
                                                                )}
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>

                                                <div className="output">
                                                    <h2>{t('answers')}</h2>
                                                    {serviceList &&
                                                        serviceList.map((singleService, index) => (
                                                            <ul key={index}>
                                                                {singleService.service &&
                                                                    <li>
                                                                        {singleService.service} & {singleService.photo}
                                                                    </li>}
                                                            </ul>
                                                        ))}
                                                </div>
                                            </form>
                                        </div>
                                        <div className="col-md-6">
                                            <form className="App" autoComplete="off">
                                                <div className="form-field">
                                                    <h5>{t('answers')}</h5>
                                                    {serviceListmulti.map((singleService, index) => (
                                                        <div key={index} className="services">
                                                            <div className="first-division">

                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <label for="photo2" class="form-label">{t("addPhoto")}</label>
                                                                        <input class="form-control" type="file" accept='image/png, image/jpg, image/jpeg' onChange={(e) => handleAnswerPhoto2(e, index)} id="photo2" name="photo2" />
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <label for="sound2" class="form-label">{t("addSound")}</label>
                                                                        <input class="form-control" type="file" accept=".mp3,audio/*" onChange={(e) => handleAnswerSound2(e, index)} id="sound2" name="sound2" />
                                                                    </div>
                                                                </div>
                                                                <label for="service" class="form-label">{t("answerText")}</label><i>boş ise 1 ader boşluk giriniz. (sadece video/resim vb var ise)</i>
                                                                <textarea class="form-control" name="service" id="service" value={singleService.service} onChange={(e) => handleServiceChange2(e, index)} required />
                                                                <hr />
                                                                {serviceListmulti.length - 1 === index && serviceListmulti.length < 6 && (
                                                                    <button
                                                                        type="button"
                                                                        onClick={handleServiceAdd1}
                                                                        className="add-btn"
                                                                    >
                                                                        <span>{t('addAnswerOption')}</span>
                                                                    </button>
                                                                )}
                                                            </div>
                                                            <div className="second-division">
                                                                {serviceListmulti.length !== 1 && (
                                                                    <button
                                                                        type="button"
                                                                        onClick={() => handleServiceRemove1(index)}
                                                                        className="remove-btn"
                                                                    >
                                                                        <span>{t('remove')}</span>
                                                                    </button>
                                                                )}
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>

                                                <div className="output">
                                                    <h2>{t('answers')}</h2>
                                                    {serviceListmulti &&
                                                        serviceListmulti.map((singleService, index) => (
                                                            <ul key={index}>
                                                                {singleService.service &&
                                                                    <li>
                                                                        {singleService.service} & {singleService.photo}
                                                                    </li>}
                                                            </ul>
                                                        ))}
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <div className='d-flex justify-content-center'>
                                        <button type="submit" class="btn btn-primary btn-lg">{t("create")}</button>
                                    </div>


                                </form>
                                <hr />
                                <hr />

                            </div>
                        )
                    }
                    else {
                        return (
                            <p>Bye</p>
                        )
                    }
                })()
            }

        </div>
    )
}
export default AdminCreateQuestionDetail;
