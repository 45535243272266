import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import axios from "axios";
import { useAuth } from '../../../../context/AuthContext';
import img1 from '../../../images/courseicon1.jpg';
import { useNavigate } from "react-router-dom";


export const UserSubjectLayout = () => {
    const { user } = useAuth();
    const [msg, setMsg] = useState([]);
    const [loading, setLoading] = useState(true);
    const { t, i18n } = useTranslation()
    const [subjects, setSubjects] = useState([]);
    const [activeCourse, setactiveCourse] = useState([]);
    const navigate = useNavigate();



    const handleClick = event => {
        navigate("/dashboard/user/course/question/0");
    };


    useEffect(() => {
        const fetchdata = async () => {
            setLoading(true);
            try {
                const headerss = {
                    "accept": "*/*",
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Credentials': 'false',
                    'Authorization': 'Bearer ' + user.token
                }
                let url = "https://api.heryerdeingilizce.com/api/user/Content/GetSubject";

                await axios({
                    method: 'GET',
                    url: url,
                    headers: headerss
                }).then(res => {

                    if (res.status === 200) {

                        if (res.data['success']) {
                            setSubjects(res.data['data']['subjects'])
                            setactiveCourse(res.data['data']['active'])
                        }
                        else {
                            setMsg(res.data['message']);
                        }
                    }
                    else {
                        setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                    }
                });
            }
            catch (err) {
                setMsg(err);
            }
            setLoading(false);
        }
        fetchdata();
    }, []);

    return (
        <div className="container">
            <br />{t('subject')}
            <div className="row">
                <h4>{msg}</h4>
                {!loading && (
                    <div>
                        {subjects && subjects.map((dt) => {
                            return (
                                <div class="card card-primary card-outline" key={dt.id}>
                                    <div class="row">
                                        <div class="col-md-9">
                                            <div class="card-body">
                                                <h5 class="card-title">{dt.subjectName}</h5>
                                                <p class="card-text">
                                                    {dt.title} -{dt.id}
                                                </p>
                                                <div id="accordion">
                                                    <button onClick={handleClick} id={dt.nameaccordion} class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={"#" + dt.nameaccordionid} aria-expanded="true" aria-controls={dt.nameaccordionid}>
                                                        <i style={{ fontSize: 25, color: "blue" }} id={dt.nameaccordion + "icon"} class="fa fa-plus-circle"></i>
                                                    </button>

                                                    <div class="accordion-collapse collapse " id={dt.nameaccordionid} aria-labelledby="headingOne" data-parent="#accordion" style={{ marginLeft: "10%" }}>
                                                        <div className="row" style={{ alignItems: "center", margin: "1px" }}>

                                                            {/* {dt.details.slice(0, 4).map((detaylar) => {
                                                                return (
                                                                    <div className="col-sm-2" key={detaylar.id} >
                                                                        <div style={{ alignItems: "center" }}>
                                                                            {icons(detaylar.type, detaylar.completed, detaylar.popoverid, dt.id, detaylar.id)}
                                                                        </div>

                                                                        <UncontrolledPopover
                                                                            placement="bottom"
                                                                            target={detaylar.popoverid}
                                                                            trigger="hover"
                                                                        >
                                                                            <PopoverHeader>
                                                                                {detaylar.name}
                                                                            </PopoverHeader>
                                                                            <PopoverBody>
                                                                                {detaylar.description}
                                                                            </PopoverBody>
                                                                        </UncontrolledPopover>

                                                                    </div>
                                                                )
                                                            })} */}

                                                            {/* <div className="col-sm-2">
                                                                {dt.detailCount > 4 &&
                                                                    <a href={"/userCo/subjects/" + dt.id}>
                                                                        <FontAwesomeIcon icon="fas fa-ellipsis-h" style={{ fontSize: 25, opacity: .9, border: "solid", borderRadius: "80%", padding: "2px", backgroundColor: "gray" }} />
                                                                    </a>
                                                                }

                                                            </div> */}

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-3" style={{ maxHeight: "100px" }}>
                                            <img style={{ maxHeight: "100px" }} src={img1} class="img-fluid rounded-start" alt="..." />
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                )}


            </div>
        </div>

    );
}
export default UserSubjectLayout;



