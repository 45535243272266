import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import axios from "axios";
import RequestHelper from '../../../../../Helpers/RequestHelper';
import { useAuth } from '../../../../../context/AuthContext';

export const GeneralSettings = () => {
    const [image, setImage] = useState('');
    const [contact, setContact] = useState([]);
    const { user } = useAuth();
    const [msg, setMsg] = useState([]);
    const [loading, setLoading] = useState(true);
    const { t, i18n } = useTranslation()
    const [dataa, setDataa] = useState('');
    const [registerData, setregisterData] = useState('');

    function handleChange(e) {
        const formData = new FormData();
        formData.append('my-image-file', e.target.files[0], e.target.files[0].name);
        setImage(formData);
    }

    const handleFile = async () => {

    }
    function handleSubmit(event) {
        registerData['isActive'] = document.getElementById("activite").value;
        RequestHelper("/notRegister", "put", registerData, "1").then(response => {
            console.log(response.statusText)
        });
    }
    function handleregister(event) {
        registerData['title'] = document.getElementById("title").value;
        registerData['subtitle'] = document.getElementById("subtitle").value;
        RequestHelper("/notRegister", "put", registerData, "1").
            then(
                response => {
                    alert(response.statusText)
                });
    }


    useEffect(() => {
        (async () => {
            const notRegister = await RequestHelper("/notRegister", "get", "", "1")
            if (notRegister.data['isActive'] === "true") {
                notRegister.data['isActive'] = "aktif"
            }
            else {
                notRegister.data['isActive'] = "pasif"
            }
            setregisterData(notRegister.data);
        })()
    }, []);


    return (
        <div className="row">
            <h2>{t('generalsettingsforhomepage')}</h2>
            <div className='row'>
                <div className='col-md-6'>
                    <img src="/images/mainlogo.png" alt="logo" style={{ padding: "10%" }} class="img-fluid" />

                </div>
                <div className='col-md-6'>
                    <br />
                    <span>Logo'da görünecek görsel. -***x*** boyutlarında olmalıdır.</span>
                    <form onSubmit={handleFile}>
                        <input class="form-control" type="file" accept='image/png, image/jpg, image/jpeg' onChange={handleChange} id="imagePath2" name="aboutImage" required />
                        <button type="submit" class="btn btn-primary btn-lg">{t("UploadImage")}</button>
                    </form>


                </div>

            </div>
            <hr />
            <div className='row'>
                <div className='col-sm-6' style={{ border: "1px solid green" }}>
                    <h2>{t('NotRegisterSettings')}</h2>
                    <h6>{t('currentStatus')} : {registerData['isActive']}</h6>
                    <form onSubmit={handleSubmit} >
                        <div class="mb-3">
                            <label for="activite" class="form-label">{t("sectionActivite")}</label>
                            <select id="activite" class="form-select" aria-label="Default select example">
                                <option key="0" value="0">{t("sectionActivite")}</option>
                                <option key="1" value={false}>{t('passive')}</option>
                                <option key="2" value={true}>{t('active')}</option>

                            </select>
                        </div>
                        <div className='d-flex justify-content-center'>
                            <button type="submit" class="btn btn-primary btn-lg">{t('update')}</button>
                        </div>
                    </form>

                </div>
                <div className='col-sm-6' style={{ border: "1px solid green" }}>
                    <form onSubmit={handleregister} >
                        <div class="mb-3">
                            <label for="title" class="form-label"> {t('title')}</label>
                            <textarea class="form-control" id="title" defaultValue={registerData.title} />
                        </div>
                        <div class="mb-3">
                            <label for="subtitle" class="form-label">{t('subTitle')}</label>
                            <textarea class="form-control" id="subtitle" defaultValue={registerData.subtitle} />
                        </div>

                        <div className='d-flex justify-content-center'>
                            <button type="submit" class="btn btn-primary btn-lg">{t('update')}</button>
                        </div>
                        <br />
                    </form>
                </div>
            </div>
        </div>
    )
}
export default GeneralSettings;